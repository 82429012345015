.common-slider .slick-slider {
  overflow-x: auto;
  overflow-y: hidden;
}
.common-slider .slick-dots li button {
  background-color: rgba(255, 255, 255, 0.5);
}
.common-slider .slick-dots li button:before {
  background-color: rgba(255, 255, 255, 0.5);
}
.common-slider .slick-dots {
  bottom: -15px;
}
.common-slider .slick-dots li {
  height: 17px;
}
.index_country p {
  padding-top: 10px;
  color: #f7f7f7;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  font-weight: 200;
  margin-bottom: 5px;
}
.index_country h3 {
  color: #ffffff;
  margin-bottom: 0;
  text-transform: uppercase;
  font-family: "Roboto Condensed";
  letter-spacing: 1px;
  font-weight: 800;
  padding-bottom: 10px;
}
.afilate-form label {
  color: #ffffff;
}
.index_country .index_title2 {
  background: rgb(35, 38, 41, 0.5);
  padding: 30px 0;
  align-items: center;
}
.affiliate-data h4 {
  font-size: 12px !important;
  color: #fff;
  margin-bottom: 0;
  text-align: left;
}

.affiliate-data h3 {
  color: #f3cca4;
  font-weight: bold;
  padding-bottom: 0;
  font-size: 15px;
}

.index_brand h2 {
  color: #fff;
  font-size: 16px;
  letter-spacing: 6px;
  text-transform: uppercase;
}
.index_brand p {
  color: #fff;
  margin-top: 0px;
  font-size: 12px;
  margin-bottom: 10px;
}

.afilate-form {
  background: rgb(35, 38, 41, 0.5);
  padding: 30px 15px;
}

.index_country .slick-initialized {
  padding: 0 34px;
}
.index_country .slick-initialized .slider-items {
  padding: 0 15px;
}
.index_country
  .slick-slide.slick-active.slick-current
  + .slick-slide.slick-active
  figure
  img {
  border: 4px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 7px rgba(255, 255, 255, 0.77);
  /* margin: 15px; */
  /* vertical-align: top; */
  /* scale: 120%; */
  width: 100%;
}

.index_country .slick-track {
  display: flex !important;
  align-items: center;
}
.index_country
  .slick-slide.slick-active.slick-current
  + .slick-slide.slick-active
  p {
  font-size: 15px;
  font-weight: bold;
}
.index_country p {
  font-size: 10px;
  text-align: center;
}

.index_country .slick-next:before {
  display: none;
}
.index_country .slick-prev:before {
  display: none;
}
.index_country .slick-slide img {
  width: 90%;
}
.index_country .slick-slide figure {
  margin: 10px 0 1rem;
  min-height: 68px;
}

.common-slider-box {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.common-slider-box .slider-items {
  display: inline-block;
  position: relative;
  width: 72vw;
  /* height: 41.3333333333vw; */
  margin: 1.3333333333vw 2.6666666667vw 1.3333333333vw 0;
  border-radius: 0.8vw;
  background: #4d5246;
  box-shadow: 0 0 0.8vw #000c;
  vertical-align: top;
}
.slot-slider-b .slider-items {
  width: 45vw;
}
.gamefixed {
  position: fixed;
  top: 55px;
  z-index: 9;
  width: 100%;
  background: #000;
  transition: all 0.3s;
}
.gamefixed .payment-box figure img {
  display: none;
}
.home-tabing-slider .gamefixed .payment-box {
  padding: 4vw 0 !important;
}
.gamefixed .payment-box figure {
  margin: 0 !important;
}
.select-group li {
  display: inline-block;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-group.checkbox-style [type="radio"]:checked + label {
  transition: all 0.3s;
  border: 0.2666666667vw solid #ffdf1a;
  background: #4d5246;
  color: #ffdf1a;
}
.select-group.checkbox-style [type="radio"] + label {
  padding: 1.3333333333vw 2.6666666667vw;
}
.select-group [type="radio"] + label {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  /* border: .2666666667vw solid #5e5e5b; */
  border-radius: 0.8vw;
  background: #0f2327;
  color: white;
  font-size: 3.2vw;
  line-height: 9.3333333333vw;
  text-align: center;
  padding: 2vw 2.6666666667vw;
  font-weight: 600;
}
.select-group .bank {
  margin-bottom: 1.3333333333vw;
}
.select-group .bank img {
  display: block;
  width: auto;
  height: 6.6666666667vw;
}
.select-group.checkbox-style [type="checkbox"] + label span,
.select-group.checkbox-style [type="radio"] + label span {
  line-height: 1.5;
}
.select-group [type="checkbox"],
.select-group [type="radio"] {
  width: 105px;
  height: 34px;
  position: absolute;
  z-index: 9;
  opacity: 0;
}
.select-group ul {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, calc((100% - 20px) / 3));
}
.select-group label span {
  line-height: 1.5;
}
.select-group.checkbox-style [type="radio"]:checked + label .item-icon {
  display: block;
  position: absolute;
  z-index: 2;
  right: -0.2666666667vw;
  bottom: 0.733333vw;
  width: 5.3333333333vw;
  height: 4.2666666667vw;
  opacity: 1;
}
.select-group.checkbox-style [type="radio"] + label .item-icon {
  display: none;
}
/* .input-group.money {
  padding: 0 0 2.6666666667vw;
} */
.input-group {
  display: flex;
  position: relative;
  flex-direction: column;
  /* padding-bottom: 2.6666666667vw; */
  font-size: 3.2vw;
  background: #4d5246;
  line-height: 1.5;
}
.input-group.money label {
  position: absolute;
  z-index: 2;
  top: 5.3333333333vw;
  left: 1.3333333333vw;
  transform: translateY(-50%);
  color: #ffdf1a;
}
.input-group.money .input-wrap {
  z-index: 1;
}
.input-group.money .input-wrap input {
  color: #ffdf1a;
  text-align: right;
}
.input-group input:disabled {
  border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3);
  border-radius: 0;
  background: none;
}
.delete-btn {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 10.6666666667vw;
  height: 10.6666666667vw;
  transition: all 0.3s;
  background: #ffdf1a;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 30%;
  mask-size: 30%;
  opacity: 0;
}
.tips-info {
  position: relative;
  flex: 1;
  margin: 0;
  padding: 2.6666666667vw;
  border: 0.2666666667vw solid #0a3e2d;
  border-radius: 1.3333333333vw;
  background: #03120d;
  text-align: left;
  white-space: normal;
}
.player-deposit-wrap .tips-info.note h5,
.withdraw-wallet .tips-info.note h5 {
  width: 100%;
  margin-bottom: 0;
  align-items: flex-start;
}
.player-deposit-wrap .tips-info.note span,
.withdraw-wallet .tips-info.note span {
  color: #fff;
  font-size: 3.2vw;
  line-height: 1.2;
  white-space: pre-wrap;
}

.usrTrans-form .member-menu-box .title {
  width: 100%;
  padding: 0;
  /* border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3); */
  line-height: 9.3333333333vw;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.usrTrans-form .member-menu-box .title i {
  display: flex;
  color: white;
  font-size: 2.6666666667vw;
  white-space: nowrap;
}
.usrTrans-form .member-menu-box {
  border-radius: 0.8vw;
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw 0;
  padding: 2vw 4vw 4vw 4vw;
  background: white;
  border-radius: 10px;
}
.usrTrans-form .select-group ul {
  padding: 0px !important;
}

.select-group ul.col4 {
  display: grid;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
  margin-bottom: 8px;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, calc((100% - 30px) / 4));
}
.input-group.money .input-wrap input {
  color: #ffdf1a;
  text-align: right;
  width: 100%;
  background: #0f2327;
  border: none;
  padding: 10px;
}
.tips-info.note span {
  color: #fff;
  font-size: 3.2vw;
  line-height: 1.2;
  white-space: pre-wrap;
}

.refer-header {
  align-items: center;
  background-color: #4d5246;
  color: #fff;
  display: flex;
  height: 120px;
  justify-content: center;
}
.refer-main {
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  margin-top: -10px;
  padding: 27px 17px;
  padding-bottom: 50px !important;
  position: relative;
}
.refer-main-top {
  margin-bottom: 15px;
}
.refer-button button:first-child {
  background-color: green;
  color: #fff;
  border: navajowhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
.refer-button button:last-child {
  background-color: grey;
  color: #fff;
  border: navajowhite;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
}
.index_country .input-group {
  padding-bottom: 0.25rem !important;
  background: none;
}

.index_country .input-group .form-control {
  padding: 0.25rem !important;
  width: 100%;
  border-radius: 0.375rem !important;
}

.index_country .input-group span {
  cursor: pointer;
  position: absolute;
  right: 0;
  border: none;
  top: 3px;
}

.index_country .slick-prev:hover,
.index_country .slick-prev:focus,
.index_country .slick-next:hover,
.index_country .slick-next:focus {
  background-size: 100% !important;
  background-repeat: no-repeat !important;
}
.top-class {
  overflow: auto;
  padding-top: 52px;
}
.refer-share-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.menu-second ul {
  position: absolute;
  top: 0;
  left: -29.3333333333vw;
  width: 29.3333333333vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.5s;
  background: #191919;
  z-index: 999;
  box-shadow: 0 0 #191919;
  padding: 0px 0 50px;
  -webkit-overflow-scrolling: touch;
  text-align: center;
}
.menu-second ul.active {
  left: 208px;
  box-shadow: 0.8vw 0 0.8vw #0000004d;
}

.menu-second ul li {
  width: 75%;
  height: auto;
  margin: 0 auto;
  padding: 5.3333333333vw 0;
  border-bottom: 0.2666666667vw solid rgb(123 121 121 / 39%);
}

.menu-second ul li figure {
  margin: 0 !important;
}

.menu-second ul li figure img {
  width: 10.6666666667vw;
}
.menu-second ul li p {
  color: #fff;
  margin-bottom: 0px;
}
.sidebar-wrapper ul li.active {
  border-left: 0.8vw solid #ffdf1a;
  background: #318456;
}
.sidebar-wrapper ul li.active span {
  color: #fff;
}
.submit-btn {
  width: 100%;
  height: 12vw;
  font-size: 4vw;
  line-height: 12vw;
  margin: 2.6666666667vw auto;
  position: relative;
  border-radius: 0.8vw;
  background: #ffdf1a;
  color: #191919;
  border: none;
  text-align: center;
}
.float-banner {
  position: fixed;
  z-index: 7999;
  bottom: 72px;
  left: 10px;
  filter: drop-shadow(0 0 1.6vw rgba(0, 0, 0, 0.5));
}
.float-banner .close {
  position: absolute;
  z-index: 2;
  top: 1vw;
  right: -4vw;
  width: 6.6666666667vw;
  height: 6.6666666667vw;
  border-radius: 100%;
  opacity: 1;
  background: #f5f5f5;
  font-size: 15px;
  text-align: center;
  text-decoration: none;
}
.float-banner img {
  width: auto;
  height: auto;
  min-width: 13.3333333333vw;
  min-height: 13.3333333333vw;
  max-width: 32vw;
  max-height: 32vw;
}
.games .games-main {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 2.1333333333vw;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.066667vw;
}
.games .games-box {
  position: relative;
  width: 25vw;
  height: auto;
  margin: 0 1.3333333333vw 2.6666666667vw;
  overflow: hidden;
  border-radius: 0.8vw;
}
.games .pic {
  position: relative;
  width: 100%;
  height: auto;
  line-height: 0;
}
.games .pic img {
  width: 100%;
  height: 20vw;
}
.games .games-box .text {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: auto;
  padding: 6px 0px 0px 8px;
  background: #ffdf1a;
}
.games .games-box .text h3 {
  width: 83%;
  overflow: hidden;
  color: #fff;
  font-size: 3vw;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.games h5 {
  margin: 15px;
}
.games h5:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4vw;
  margin-right: 1.3333333333vw;
  background-color: #b50f58;
}
.all-casino-page {
  padding-bottom: 30px;
  padding-top: 53px;
  background-color: #fff;
}
.search-tab ul {
  padding-top: 2.4vw !important;
  line-height: 0;
  text-align: left;
  width: 100%;
  height: 100%;
  padding: 5px 0px 6px 2.666667vw !important;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.hot-listing li {
  /* background-color: #005dac!important;
    color: #fff; */
  background: #f5f5f5 !important;
  color: #555 !important;
  margin-right: 2.6666666667vw !important;
  padding: 4px 2.666667vw !important;
  border-radius: 0.8vw !important;
  font-size: 3.4666666667vw !important;
  line-height: 8vw !important;
  vertical-align: middle !important;
  width: auto !important;
}
.hot-listing li.active {
  background-color: #44a56f !important;
  color: #fff !important;
}
.search-tab {
  background: #ffffff;
  margin-bottom: 4.2666666667vw;
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 13.3333333333vw;
  border-top: 0.2666666667vw solid #eeeeee;
  border-bottom: 0.2666666667vw solid #eeeeee;
}
.affliate-blink {
  -webkit-animation: twinkling 1.4s ease infinite;
  animation: twinkling 1.4s ease infinite;
}
@keyframes twinkling {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 0px #fe1dad, 0px 0px 0px #fe1dad, 0px 0px 0px #fe1dad;
    box-shadow: 0px 0px 0px 0px #fe1dad, 0px 0px 0px 0px #fe1dad,
      0px 0px 0px 0px #fe1dad, inset 0px 0px 0px #fe1dad;
  }
  20% {
    opacity: 0.5;
    text-shadow: 0px 0px 2.5px #fe1dad, 0px 0px 5px #fe1dad,
      1px 1px 25px #fe1dad;
    box-shadow: 0px 0px 5px 0px #fe1dad, 0px 0px 10px 0px #fe1dad,
      0px 0px 50px 0px #fe1dad, inset 0px 0px 20px #fe1dad;
  }
  40% {
    opacity: 0;
    text-shadow: 0px 0px 1px #fe1dad, 0px 0px 2px #fe1dad, 0px 0px 10px #fe1dad;
    box-shadow: 0px 0px 2px 0px #fe1dad, 0px 0px 5px 0px #fe1dad,
      0px 0px 25px 0px #fe1dad, inset 0px 0px 10px #fe1dad;
  }
  50% {
    opacity: 1;
    text-shadow: 0px 0px 5px #fe1dad, 0px 0px 10px #fe1dad, 1px 1px 50px #fe1dad;
    box-shadow: 0px 0px 10px 0px #fe1dad, 0px 0px 20px 0px #fe1dad,
      0px 0px 100px 0px #fe1dad, inset 0px 0px 30px #fe1dad;
  }
  100% {
    opacity: 1;
    text-shadow: 0px 0px 5px #fe1dad, 0px 0px 10px #fe1dad, 1px 1px 50px #fe1dad;
    box-shadow: 0px 0px 10px 0px #fe1dad, 0px 0px 30px 0px #fe1dad,
      0px 0px 100px 0px #fe1dad, inset 0px 0px 30px #fe1dad;
  }
}
.login-reg-wrapper .language-select-div {
  width: auto;
  vertical-align: middle;
  text-align: center;
  color: #000;
  line-height: 16px;
  font-size: 14px;
}
.login-reg-wrapper .language-select-div img {
  margin: auto !important;
  height: 20px;
}

.mobile-header-item a {
  color: black;
  text-decoration: none;
}
.deposit-tab {
  /* margin-top: 60px; */
}
.mobile-header-item a.router-link-active {
  color: black;
}
.v-list-item__content {
  display: flex;
  justify-content: space-between;
}
.v-list-item__content svg {
  fill: #000 !important;
}
.accordion-header svg {
  margin-right: 12px;
}
.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}
.v-list-item__title.childNav-title {
  color: #9b9b9b !important;
  font-size: 0.88rem !important;
  text-decoration: none;
  padding: 12px 0;
  font-weight: 600;
  outline: none;
}
.accordion-body a {
  text-decoration: none !important;
}

.blinkPulse:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: -13px;
  left: -10px;
  background-color: #00ce47;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 0.6;
  animation: pulse 1.8s ease-out;
  animation-iteration-count: infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.3);
    opacity: 1;
  }
  50% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  75% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.pulse:after,
.pulse:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #fddd04;
  border-radius: 50%;
  animation: animate 2s linear infinite;
}
@keyframes animate {
  0% {
    box-shadow: 0 0 0.0625rem #fddd04;
  }

  30%,
  35% {
    box-shadow: 0 0 2.1875rem #fddd04;
  }

  60%,
  65% {
    box-shadow: 0 0 0 #fddd04;
  }

  to {
    box-shadow: 0 0 1px #fddd04;
  }
}
.pulse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.1875rem;
  height: 12.1875rem;
  border-radius: 9.375rem;
  background: #191919ad;
}
.v-overlay__content {
  position: relative;
}
.common-slider-box .sports-tab-item {
  border-radius: 0.8vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 0px 5px;
  margin: 0;
}
.sports-tab-panel h3 {
  margin-bottom: 0px !important;
}
.sports-tab-panel .sports-tab-item .active {
  background-color: #ffdf1a;
  color: #000;
}
.sports-tab-panel h3 {
  font-size: 12.48px !important;
  position: relative;
  font-weight: 700;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
}

.sports-tab-panel ul li {
  font-size: 18px;
  padding: 10px 16px;
  font-weight: 700;
  font-family: "Inter";
}

.sports-tab-panel ul li:first-child {
  padding-left: 0px;
}

::-webkit-scrollbar {
  height: 1px;
}
.sports-tab-panel h3 {
  margin-bottom: 0px !important;
}

/*=== deposit page css ===*/

.dw-p .common-box {
  /* border: 1px solid #00000030; */
  /* border-radius: 6px; */
  margin-bottom: 16px;
}

.p-title {
  align-items: center;
  /* border-bottom: 2px solid #ffdf1a; */
  display: flex;
  padding: 58px 20px 8px;
}
.p-title .title {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
.deposit-tab .mobile-header-item.selected {
  background: #18b0c8;
  border-bottom: none;
  margin-bottom: 0;
  padding: 6px;
}
.deposit-tab .mobile-header-item.selected a {
  color: white;
}
.deposit-tab .mobile-header-item {
  align-items: center;
  background: transparent;
  border-bottom: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding: 6px;
}

.dw-p span.amt {
  font-size: 2rem;
  font-weight: 700;
  width: auto;
  display: inline-block;
}
.border-t {
  border-top: 1px solid #00000030;
}

.dw-p .timer-title {
  display: inline-block;
  margin-right: 5px;
}
.dw-p .timer-val {
  display: inline-block;
}

.dw-p .timer-val .text-yellow {
  color: #fff !important;
}

.form-f .text-input,
.form-f select,
.form-f .fileInput {
  padding: 8px;
  border: 1px solid #00000030;
  border-radius: 6px;
  width: 100%;
  margin-bottom: 6px;
}

.form-f .transaction-title {
  margin-bottom: 5px;
  display: inline-block;
  color: black;
  font-weight: 600;
}
.form-f .transaction-title .important-icon {
  color: red;
}

.fileupload-box {
  position: relative;
  /* background: #d7e3f0; */
  border-radius: 6px;
  border: 2px dashed black;
  margin-bottom: 6px;
  overflow: hidden;
}
.form-f .fileupload-box span {
  position: relative;
  padding: 8px;
  width: 100%;
  font-weight: 600;
  display: block;
}

.form-f .fileupload-box input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  margin: 0;
}

.btn-submit {
  padding: 8px;
  border: none;
  border-radius: 6px;
  width: 100%;
  background-color: #18b0c8;
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin-bottom: 6px;
  margin-top: 10px;
}

.btn-remove {
  border: none;
  padding: 10px 0;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  border-radius: 20px;
}

.mb-66 {
  margin-bottom: 66px !important;
}
.dw-box {
  background: #18b0c8;
  /* border: 1px solid #4d5246; */
  color: #fff;
  margin-top: 10px;
}
.withdraw-box {
  height: 100vh;
}
.select-container span .text-danger {
  color: #14805e !important;
  margin: 0px 4px;
  font-size: 16px;
}

.bkash-loader {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #44a56f;
}

.header-outer {
  border: 1px solid lightgrey;
  padding: 4px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.walletBalance-button {
  font-size: 12px;
  border: 1px solid lightgrey;
  padding: 3px 6px;
  border-radius: 5px;
}
.walletBalance-outer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card1 ul li .icon-sport {
  width: 10.6666666667vw;
  height: 10.6666666667vw;
  background: #ffdf1a;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  margin: 0 auto;
}


.deposit-info {
  font-size: 12px;
  font-weight: 600;
}