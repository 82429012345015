.registration-form,
.login-form {
  position: fixed;
  /* top: 0; */
  bottom: -100%;
  background: #191919;
  /* padding: 20px; */
  right: 0;
  left: 0;
  transition: 0.5s;
}
.registration-form .main-banner {
  background: none;
}
.reg-data h2 {
  font-size: 23px;
  font-weight: 100;
  line-height: 31px;
  color: #fff;
}
.reg-data h2 strong {
  font-size: 20px;
}
.registration-form label,
.login-form label {
  display: block;
  margin-bottom: 0;
  color: #ffffff;
  font-size: 3.4666666667vw;
  margin-right: 20px;
  width: 50%;
}
.reg-data {
  margin-bottom: 16px;
  padding: 9px;
}

.registration-form .form-control,
.login-form .form-control {
  padding: 8px !important;
  margin-left: 0px;
  border: none;
  width: 100%;
  color: #fff;
  font-size: 15px;
  background: transparent;
  border: none;
  font-size: 3.4666666667vw;
}

.registration-form .form-control:focus,
.login-form .form-control:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.registration-form form span,
.login-form form span {
  display: block;
  overflow: hidden;
  color: #191919;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 20px;
  font-size: 17px;
  font-weight: 500;
}

.registration-form .form-group,
.login-form .form-group {
  /* border-bottom: 1px solid #00000026; */
  padding-bottom: 8px;
}

.registration-form p,
.login-form p {
  color: #ffffff;
  font-size: 3.2vw;
  line-height: 1.2;
  text-align: center;
}

.registration-form p a,
.login-form p a {
  color: #ffffff;
  font-weight: 600;
}

.registration-form .login-form {
  position: fixed;
  top: 100%;
  background: rgb(17, 17, 17);
  padding: 20px;
  right: 0;
  left: 0;
  transition: 0.5s;
  height: 100%;
}
.registration-form.active,
.login-form.active {
  top: 0px;
}

.registration-form form .login-form form {
  margin-top: 29px;
}

.registration-form form .next-btn span {
  font-weight: 600;
  font-family: "Inter";
  font-size: 16px;
}

.member-menu {
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow-x: hidden; */
  overflow-y: auto;
  margin-bottom: 80px;
  transition: all 0.4s;
  -webkit-overflow-scrolling: touch;
}
.member-menu .close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 13.3333333333vw;
  height: 13.3333333333vw;
  border-radius: 0 0 0 100%;
  background: #111111;
}

.member-header .member-header-content {
  bottom: 54px;
  left: 50px;
}
.member-header-content {
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  bottom: 32vw;
  left: 2.6666666667vw;
  z-index: 1;
}
.member-header-content .pic {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 2.6666666667vw;
  border-radius: 17.3333333333vw;
  background-color: #111;
  background-repeat: no-repeat;
  color: #ffdf1a;
  font-size: 9.6vw;
  line-height: 0;
  text-align: center;
  vertical-align: middle;
  box-shadow: 2px 4px 7px #0000003d;
  background-position: center;
  background-size: contain;
}
.member-header-content .infor {
  display: inline-block;
  width: 74.6666666667vw;
  vertical-align: middle;
}
.member-header-content .account {
  margin-bottom: 1.3333333333vw;
  color: #fff;
  font-size: 4.2666666667vw;
}
.member-header-content .account span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  width: 64vw;
  max-height: 17.0666666667vw;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 4.2666666667vw;
}
.member-header-content .vip-points {
  display: inline-flex;
  position: relative;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 100%;
  width: auto;
  min-height: 6.4vw;
  padding: 0.5333333333vw 2.6666666667vw;
  margin-bottom: 0.5333333333vw;
  border-radius: 6.4vw;
  background: #262626;
  color: #fff;
  font-size: 3.2vw;
  white-space: nowrap;
  line-height: 1.5;
  overflow: scroll;
}
.member-header-content .vip-points.active span {
  flex: 0 1 25px;
  max-width: none;
  overflow: visible;
}
.member-header-content .vip-points .myvip-text .item-icon {
  display: inline-block;
  flex: 0 0 2.9333333333vw;
  width: 2.9333333333vw;
  height: 2.6666666667vw;
  margin: 0 0 0 1.3333333333vw;
  background: #ffffff;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: cover;
  mask-size: cover;
  vertical-align: middle;
}

.member-menu-content {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 0 2.6666666667vw;
}
.member-menu-box.balance-box {
  display: flex;
  position: relative;
  flex-direction: row;
}
.member-menu-box {
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw;
  border-radius: 0.8vw;
  /* background: #18b0c8; */
}
.member-menu-box.balance-box .balance.balance-row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.member-menu-box.balance-box .balance .text {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
}
.member-menu-box .title {
  width: 100%;
  padding: 0 2.6666666667vw;
  /* border-bottom: 0.2666666667vw solid rgba(153, 153, 153, 0.3); */
  line-height: 9.3333333333vw;
}
.member-menu-box .title h2 {
  color: black;
  font-size: 4vw;
  margin: 8px 0;
}
/* .member-menu-box .title h2:before {
  content: "";
  display: inline-block;
  width: 1.0666666667vw;
  height: 4.2666666667vw;
  margin-right: 1.3333333333vw;
  background: #c0a024;
  vertical-align: middle;
} */
.member-menu-box .title h2 span {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  vertical-align: middle;
}
.member-menu-box ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px;
  margin: 0;
}
.member-menu-box ul.align-center li {
  flex: auto;
  width: 33.3333333333%;
}
.member-menu-box ul li a {
  display: block;
  text-decoration: none;
}
/* .member-menu-box ul li a .item-icon {
    display: block;
    width: 9.3333333333vw;
    height: 9.3333333333vw;
    margin: 0 auto 1.3333333333vw;
    border-radius: 9.3333333333vw;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  } */
.member-menu-box ul li a p {
  /* color: #fff; */
  margin: 0;
  font-size: 3.4666666667vw;
  text-align: center;
}
.member-menu-logout {
  width: 100%;
  height: auto;
  margin: 0 0 2.6666666667vw;
  border-radius: 0.8vw;
  background: #18b0c8;
}
.member-menu-logout a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.6666666667vw;
  text-decoration: none;
}
.member-menu-logout a p {
  display: inline-block;
  color: #fff;
  font-size: 3.4666666667vw;
  text-align: center;
  margin: 0;
}
.member-menu-box.balance-box .balance {
  display: inline-flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  height: 18.4vw;
  padding: 2.6666666667vw;
  font-size: 3.2vw;
}
.member-menu-box.balance-box .balance .amount i {
  display: inline-block;
  position: relative;
  transition: all 0.1s ease-in;
  color: #ffdf1a;
}
.member-menu .close:after {
  transform: rotate(-45deg);
}
.member-menu .close:before {
  transform: rotate(45deg);
}
.member-menu .close:before,
.member-menu .close:after {
  content: "";
  display: block;
  position: absolute;
  top: 40%;
  left: 60%;
  width: 5.3333333333vw;
  height: 0.5333333333vw;
  margin: -0.2666666667vw 0 0 -2.6666666667vw;
  border-radius: 0.5333333333vw;
  background: #ffffff;
}

.mobile-header-item a {
  color: #fff;
  text-decoration: none;
}
.deposit-tab {
  /* margin-top: 60px; */
}
.deposit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.deposit a {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  flex-direction: column !important;
}
.deposit a p {
  font-size: 15px !important;
  color: black !important;
  font-weight: 500;
}
.withdrawal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.withdrawal a {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  flex-direction: column !important;
}
.withdrawal a p {
  font-size: 15px !important;
  color: black !important;
  font-weight: 500;
}
.v-list-item__content {
  display: flex;
  justify-content: space-between;
}
.v-list-item__content svg {
  fill: #000 !important;
}
.accordion-header svg {
  margin-right: 12px;
}
.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent !important;
  box-shadow: none !important;
}
.v-list-item__title.childNav-title {
  color: #9b9b9b !important;
  font-size: 0.88rem !important;
  text-decoration: none;
  padding: 12px 0;
  font-weight: 600;
  outline: none;
}
.accordion-body a {
  text-decoration: none !important;
}

.blinkPulse:after {
  position: absolute;
  content: " ";
  z-index: -1;
  top: -13px;
  left: -10px;
  background-color: #00ce47;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  opacity: 0.6;
  animation: pulse 1.8s ease-out;
  animation-iteration-count: infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.3);
    opacity: 1;
  }
  50% {
    transform: scale(0.6);
    opacity: 0.6;
  }
  75% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
.pulse:after,
.pulse:before {
  content: "";
  position: absolute;
  left: -2px;
  top: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid #fddd04;
  border-radius: 50%;
  animation: animate 2s linear infinite;
}
@keyframes animate {
  0% {
    box-shadow: 0 0 0.0625rem #fddd04;
  }

  30%,
  35% {
    box-shadow: 0 0 2.1875rem #fddd04;
  }

  60%,
  65% {
    box-shadow: 0 0 0 #fddd04;
  }

  to {
    box-shadow: 0 0 1px #fddd04;
  }
}
.pulse {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.1875rem;
  height: 12.1875rem;
  border-radius: 9.375rem;
  background: #191919ad;
}
.v-overlay__content {
  position: relative;
}
.common-slider-box .sports-tab-item {
  border-radius: 0.8vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  padding: 0px 5px;
  margin: 0;
}
.sports-tab-panel h3 {
  margin-bottom: 0px !important;
}
.sports-tab-panel .sports-tab-item .active {
  background-color: #ffdf1a;
  color: #000;
}
.sports-tab-panel h3 {
  font-size: 12.48px !important;
  position: relative;
  font-weight: 700;
  text-align: center;
  padding: 5px 10px;
  border-radius: 4px;
}

.sports-tab-panel ul li {
  font-size: 18px;
  padding: 10px 16px;
  font-weight: 700;
  font-family: "Inter";
}

.sports-tab-panel ul li:first-child {
  padding-left: 0px;
}

::-webkit-scrollbar {
  height: 1px;
}
.sports-tab-panel h3 {
  margin-bottom: 0px !important;
}
